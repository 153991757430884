/*!
 *
 */
@use "sass:math";
@import "../variables";

@mixin underline($x: 'left', $x-val: 0,$bottom: -20px,$width: 100%, $height: 20px, $rotate: 3deg ,$color: $secondary-color) {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: $bottom;
    @if ($x == 'left') {
      left: $x-val;
    } @else {
      right: $x-val;
    }
    height: $height;
    width: $width;
    border-style: solid;
    border-width: 5px;
    border-color: $color transparent transparent transparent;
    border-radius: 50%;
    transform: rotate($rotate);
  }
}

@mixin safari-underline($bg-color: $default-background-color, $x: 'left', $x-val: 0,$bottom: -20px,$width: 100%, $height: 20px, $rotate: 3deg ,$color: $secondary-color) {
  @include underline($x, $x-val, $bottom, $width, $height, $rotate, $color);

  &:before {
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: calc(#{$bottom} - #{math.div($height, 3)});
    @if ($x == 'left') {
      left: $x-val;
    } @else {
      right: $x-val;
    }
    height: $height;
    width: $width;
    background-color: $bg-color;
    border-radius: 50%;
    transform: rotate($rotate);
    z-index: 2;
  }

}

