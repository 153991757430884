/*!
 *
 */

@import "../variables";

// **************** COLORS ****************

@each $name, $color in $color-names {
  .#{$name} {
    color: #{$color} !important;
  }
  .bg-#{$name} {
    background-color: #{$color} !important;
  }
  .stroke-#{$name} {
    stroke: #{$color} !important;
  }
  .fill-#{$name} {
    fill: #{$color} !important;
  }

  * {
    &:hover {
      &.hover-#{$name} {
        color: #{$color} !important;
      }

      &.hover-bg-#{$name} {
        background-color: #{$color} !important;
      }

      &.hover-stroke-#{$name} {
        stroke: #{$color} !important;
      }

      &.hover-fill-#{$name} {
        fill: #{$color} !important;
      }
    }

    &:disabled {
      &.disabled-#{$name} {
        color: #{$color} !important;
      }

      &.disabled-bg-#{$name} {
        background-color: #{$color} !important;
      }

      &.disabled-stroke-#{$name} {
        stroke: #{$color} !important;
      }

      &.disabled-fill-#{$name} {
        fill: #{$color} !important;
      }
    }
  }
}

// **************** HELPER COLORS ****************

.transparent {
  color: transparent !important;
}
