/*!
 *
 */

@import "keyframes";
@import "transition";


@mixin fade-in-out($selector, $active, $y-direction: 50%, $y-direction-name: top, $right: 0, $duration: $default-transition-duration) {
  $fade-in-name: unique-id();
  $fade-out-name: unique-id();

  @include keyframes($fade-in-name) {
    from {
      opacity: 0;
      transform: translateY($y-direction) translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateY($y-direction) translateX(0);
    }
  }

  @include keyframes($fade-out-name) {
    from {
      opacity: 1;
      transform: translateY($y-direction) translateX(0);
    }
    to {
      opacity: 0;
      transform: translateY($y-direction) translateX(100%);
    }
  }

  #{$selector} {
    position: absolute;
    @if ($y-direction-name == top) {
      top: 0;
    } @else {
      bottom: 0;
    }
    right: $right;
    animation-name: $fade-out-name;
    animation-fill-mode: forwards;
    animation-duration: $duration;

    &#{$active} {
      animation-name: $fade-in-name;
      transform: translateY($y-direction) translateX(0);
    }
  }

  &:hover {
    #{$selector} {
      animation-name: $fade-in-name;
      animation-fill-mode: forwards;
    }
  }

}
