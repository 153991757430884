/*!
 *
 */

@import "app-font-face";

//@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-Thin", 100, normal, ttf);
//@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-ThinItalic", 100, italic, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-ExtraLight", 200, normal, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-ExtraLightItalic", 200, italic, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-Light", 300, normal, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-LightItalic", 300, italic, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-Regular", 400, normal, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-Italic", 400, italic, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-Medium", 500, normal, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-MediumItalic", 500, italic, ttf);
//@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-SemiBold", 600, normal, ttf);
//@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-SemiBoldItalic", 600, italic, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-Bold", 700, normal, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-BoldItalic", 700, italic, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-ExtraBold", 800, normal, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-ExtraBoldItalic", 800, italic, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-Black", 900, normal, ttf);
@include app-font-face('Nunito', "/assets/fonts/Nunito/Nunito-BlackItalic", 900, italic, ttf);

@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-Thin", 100, normal, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-ThinItalic", 100, italic, ttf);
//@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-ExtraLight", 200, normal, ttf);
//@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-ExtraLightItalic", 200, italic, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-Light", 300, normal, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-LightItalic", 300, italic, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-Regular", 400, normal, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-Italic", 400, italic, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-Medium", 500, normal, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-MediumItalic", 500, italic, ttf);
//@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-SemiBold", 600, normal, ttf);
//@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-SemiBoldItalic", 600, italic, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-Bold", 700, normal, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-BoldItalic", 700, italic, ttf);
//@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-ExtraBold", 800, normal, ttf);
//@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-ExtraBoldItalic", 800, italic, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-Black", 900, normal, ttf);
@include app-font-face('Roboto', "/assets/fonts/Roboto/Roboto-BlackItalic", 900, italic, ttf);
