/*!
 *
 */

@import "../variables";
@import "./keyframes";

@mixin animating-icon-pulse($color, $duration: $default-transition-duration, $play-state: infinite, $background-opacity: 0.15) {
  $name: unique-id();
  @include keyframes($name) {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($color, $background-opacity);
    }

    70% {
      transform: scale(1.1);
      box-shadow: 0 0 0 10px rgba($color, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($color, 0);
    }
  }

  border-radius: 50%;
  background-color: rgba($color, $background-opacity);
  animation: $name $duration $play-state;
}
