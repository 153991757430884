/*!
 *
 */
@import './variables';

%default-loading {
  div {
    position: relative;
    border-radius: 2px;

    &:after {
      border-radius: 2px;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      animation-duration: 15s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeholder-shimmer;
      animation-timing-function: linear;
      background: #fff;
      background-size: 100vw;
    }
  }
}


html {
  // TODO: color change of loading containers.
  &[data-theme="dark"] {
    %default-loading {
      div {
        background-color: rgba(0, 0, 0, .1);

        &:after {
          background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
        }
      }
    }
  }

  &[data-theme="light"] {
    %default-loading {
      div {
        background-color: rgba(0, 0, 0, .1);

        &:after {
          background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
        }
      }
    }
  }

  .loading.app-card {
    background-color: transparent;
    pointer-events: none;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }

  .loading.home-items-container {
    .header {
      @extend %default-loading;

      div {
        + div {
          margin-top: 0;
        }
      }

      div:nth-of-type(1) {
        width: 20%;
        height: 2rem;

        &:before {
          bottom: -30px !important;
        }
      }

      div:nth-of-type(2) {
        width: 5rem;
        height: 2rem;
      }
    }
  }

  .loading.course-card {

    // Medium Course Card.
    &.medium {
      @extend %default-loading;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2rem;

      > div {
        flex: 0 0 auto;
      }

      div:nth-of-type(1) {
        width: 100%;
        height: 1rem;
      }

      div:nth-of-type(2) {
        width: 40%;
        height: 1rem;
        margin-top: 0.5rem;
      }

      div:nth-of-type(3) {
        width: 100%;
        height: 10rem;
        margin-top: 1rem;
      }

      div:nth-of-type(4) {
        width: 70%;
        margin: 1rem auto 0;
        height: 1.5rem;
      }

      section {
        @extend %default-loading;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        div:nth-of-type(1) {
          width: 30%;
          height: 1rem;
          margin-top: 0.25rem;
        }

        div:nth-of-type(2) {
          width: 30%;
          height: 1rem;
          margin-top: 0.25rem;
        }
      }
    }

    // Large Course Card.
    &.large {
      display: flex;
      flex-wrap: wrap;
      align-items: start;

      > div:nth-of-type(1) {
        width: 100%;
        max-width: 55%;
        padding-right: 1.5rem;
        @extend %default-loading;

        > div {
          width: 100%;
          height: 17.25rem;
        }
      }

      > div:nth-of-type(2) {
        @extend %default-loading;
        width: 100%;
        max-width: 45%;
        display: flex;
        flex-direction: column;

        div:nth-of-type(1) {
          width: 100%;
          height: 1.25rem;
        }

        div:nth-of-type(2) {
          margin-top: 0.25rem;
          width: 40%;
          height: 1.25rem;
        }

        section {
          margin: 1rem 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div:first-of-type {
            width: 20%;
            height: 1rem;
          }

          > div:last-of-type {
            width: 20%;
            height: 1rem;
          }
        }

        div:nth-of-type(3),
        div:nth-of-type(4),
        div:nth-of-type(5),
        div:nth-of-type(6),
        div:nth-of-type(7),
        div:nth-of-type(8),
        div:nth-of-type(9) {
          margin-bottom: 0.5rem;
          width: 100%;
          height: 0.75rem;
        }

        div:nth-of-type(9) {
          width: 50%;
        }

        div:nth-of-type(10) {
          margin-top: 0.5rem;
          height: 2rem;
          width: 30%;
        }

      }
    }

  }

  .loading.video-card {

    // Small Video Card.
    &.small {
      display: flex;
      flex-wrap: wrap;

      > div > div {
        flex: 0 0 auto;
      }

      $size: 7rem;
      $width: $size + 4rem;
      $padding: 0.5rem;

      div:nth-of-type(1) {
        @extend %default-loading;
        padding-right: $padding;

        div:nth-of-type(1) {
          width: $width;
          height: $size;
        }
      }

      div:nth-of-type(2) {
        @extend %default-loading;
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - #{$width} - #{$padding});

        > div:nth-of-type(1) {
          width: 100%;
          height: 1rem;
        }

        > div:nth-of-type(2) {
          width: 40%;
          height: 1rem;
          margin-top: 0.25rem;
          margin-right: auto;
        }

        span {
          width: 100%;
          height: 0;
          padding: 0;
          margin: 0;
        }
      }
    }

    // Medium Video Card.
    &.medium {
      display: flex;
      flex-wrap: wrap;

      > div > div {
        flex: 0 0 auto;
      }

      $size: 7rem;
      $width: $size + 4rem;
      $padding: 0.5rem;

      div:nth-of-type(1) {
        @extend %default-loading;
        padding-right: $padding;

        div:nth-of-type(1) {
          width: $width;
          height: $size;
        }
      }

      div:nth-of-type(2) {
        @extend %default-loading;
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - #{$width} - #{$padding});

        > div:nth-of-type(1) {
          width: 100%;
          height: 1rem;
        }

        > div:nth-of-type(2) {
          width: 40%;
          height: 1rem;
          margin-top: 0.25rem;
          margin-right: auto;
        }

        span {
          width: 100%;
          height: 0;
          padding: 0;
          margin: 0;
        }

        > div:nth-of-type(3) {
          width: 20%;
          margin-top: 0.75rem;
          height: 1.5rem;
        }
      }
    }
  }

  .loading.course-information-view {
    .cover {
      @extend %default-loading;

      div {
        height: 30rem;
        width: 100%;
      }
    }

    .content {
      @extend %default-loading;

      > div {
        height: 1rem;

        + div {
          margin-top: 0.4rem;
        }
      }

      > div:last-of-type {
        width: 40%;
      }
    }

    .video-title {
      @extend %default-loading;

      div {
        width: 10rem;
        height: 2rem;
        margin: 1rem 0 3rem;
      }
    }

  }

  .loading.video-information-view {

    &:not(.belongs-to-course) {
      > .container {
        > .row {
          > div[class*=col-] {
            &:nth-of-type(1) {
              @extend %default-loading;

              div {
                width: 100%;
                height: 50vw;
              }
            }

            &:nth-of-type(2) {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              padding-top: 2rem;

              > div {

                &:nth-of-type(1) {
                  @extend %default-loading;
                  width: 70%;
                  padding: 0;

                  div {
                    width: 80%;
                    height: 2rem;
                  }
                }

                &:nth-of-type(2) {
                  @extend %default-loading;
                  width: 30%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                  div {
                    width: 2rem;
                    height: 2rem;

                    + div {
                      margin-left: 0.5rem;
                    }
                  }
                }

                &:nth-of-type(3) {
                  width: 100%;

                  > div {
                    @extend %default-loading;
                    margin-top: 1rem;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    > div {
                      width: 8rem;
                      height: 2rem;
                    }
                  }
                }

                &:nth-of-type(4) {
                  width: 100%;
                  margin-top: 2rem;
                }

                &:nth-of-type(5) {
                  > div {
                    @extend %default-loading;
                    width: 7rem;
                    margin-right: 0.7rem;

                    div {
                      height: 1.5rem;
                    }
                  }
                }
              }

              section {
                margin: 3rem 0 4rem;
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                > div {
                  @extend %default-loading;
                  width: 100%;

                  > div {
                    height: 1rem;
                  }

                  + div {
                    margin-top: 0.5rem
                  }
                }

                > div:last-of-type {
                  width: 40%;
                }

              }

            }

          }

        }
      }
    }

    &.belongs-to-course {
      > .container {
        > .row {
          > div[class*=col-] {
            &:nth-of-type(1) {
              @extend %default-loading;

              div {
                width: 100%;
                height: 50vw;
              }
            }

            &:nth-of-type(2) {
              margin: 2rem 0 3rem;

              > div {
                &:first-of-type {
                  @extend %default-loading;
                  width: 40%;

                  > div {
                    height: 2rem;
                  }
                }
              }
            }

            &:nth-of-type(3) {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              padding-top: 2rem;

              > div {

                &:nth-of-type(1) {
                  @extend %default-loading;
                  width: 70%;
                  padding: 0;

                  div {
                    width: 80%;
                    height: 2rem;
                  }
                }

                &:nth-of-type(2) {
                  @extend %default-loading;
                  width: 30%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                  div {
                    width: 2rem;
                    height: 2rem;

                    + div {
                      margin-left: 0.5rem;
                    }
                  }
                }

                &:nth-of-type(3) {
                  width: 100%;

                  > div {
                    @extend %default-loading;
                    margin-top: 1rem;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    > div {
                      width: 8rem;
                      height: 2rem;
                    }
                  }
                }

                &:nth-of-type(4) {
                  width: 100%;
                  margin-top: 2rem;
                }

                &:nth-of-type(5) {
                  > div {
                    @extend %default-loading;
                    width: 7rem;
                    margin-right: 0.7rem;

                    div {
                      height: 1.5rem;
                    }
                  }
                }
              }

              section {
                margin: 3rem 0 4rem;
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                > div {
                  @extend %default-loading;
                  width: 100%;

                  > div {
                    height: 1rem;
                  }

                  + div {
                    margin-top: 0.5rem
                  }
                }

                > div:last-of-type {
                  width: 40%;
                }

              }

            }

          }

        }
      }
    }
  }

}
