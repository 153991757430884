/*!
 *
 */

@mixin center-image($width:100%,$height:auto) {
  position: relative;
  overflow: hidden;
  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    @if $width == 100% {
      max-width: 100%;
    } @else {
      max-width: none;
    }
    width: $width;
    height: $height;
  }
}
