/*!
 *
 */

@import "../variables";
@import "../mixins";

// **************** Text Generation ****************

$text-sizes: (
        'text-xxs':$text-xxs,
        'text-xs':$text-xs,
        'text-sm':$text-sm,
        'text-md':$text-md,
        'text-lg':$text-lg,
        'text-xl':$text-xl,
        'text-title-xs':$text-title-xs,
        'text-title-sm':$text-title-sm,
        'text-title-md':$text-title-md,
        'text-title-lg':$text-title-lg,
        'text-title-xl':$text-title-xl,
);

@each $name, $size in $text-sizes {
  .#{$name} {
    @include rfs($size, true);
  }
}
