/*!
 *
 */
@use "sass:math";
@import "variables";
@import "extensions";
@import "mixins";
@import "functions";

%sharp-image {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  //noinspection CssInvalidPropertyValue
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

// CSS for general components and tags in the application
html {

  &[data-theme][transition] {
    * {
      transition-property: color, background-color, stroke, fill;
      transition-timing-function: ease-in-out;
      transition-duration: $color-theme-transition-duration;
    }
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: smooth;

    &:focus, &:active {
      outline: none !important;
    }
  }

  body {
    @include font-family(('Roboto', 'Nunito'));
    margin: 0;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $default-background-color;
  }

  a {
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  p {
    margin-bottom: 0;
  }

  img {
    &.sharp {
      @extend %sharp-image;
    }
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    //-webkit-box-shadow: 0 0 0 30px white inset !important;
    transition: background-color 0s 50000000000s;
  }

  //noinspection CssInvalidPseudoSelector
  input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent !important;
    color: $primary-text-color !important;
  }
}

// CSS for Specific components in the app
html {

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  .transition {
    @include transition();
  }

  .Toastify__toast-body {

    .Toastify__toast-icon {
    }

    align-items: flex-start;
    @include rfs($text-sm);
  }

  .button {
    min-width: 5rem;
    box-shadow: none;
    font-weight: 600;
    @include rfs($text-xs);

    &.MuiButton-contained {
      box-shadow: 0 6px 30px $contained-button-box-shadow-color;
    }

    &:not(&[class^='MuiButton-text'], &[class*=' MuiButton-text']) {
      text-transform: uppercase;
      min-height: 2rem;
    }
  }

  .icon-button {
    .custom {
      .fill {
        @include transition((fill));
        fill: $icon-button-svg-color;
      }

      .stroke {
        @include transition((stroke));
        stroke: $icon-button-svg-color;
      }
    }

    .mui {
      @include transition((color, stroke));
      color: $icon-button-svg-color;

      &.submit {
        color: $primary-color;
        stroke: $primary-color;
        stroke-width: 1px;
      }

      &.revert {
        color: $red-dark;
        stroke: $red-dark;
        stroke-width: 1px;
      }
    }

    &.save {
      $icon-button-padding: 0.5rem;
      padding: $icon-button-padding;

      .fill {
        fill: $primary-text-color;
      }

      .stroke {
        stroke: $primary-text-color;
      }

      &.active {
        .fill {
          fill: $hint-text-color;
        }

        .stroke {
          stroke: $hint-text-color;
        }
      }
    }

    &:hover {
      .custom {
        .fill {
          fill: $icon-button-svg-hover-color;
        }

        .stroke {
          stroke: $icon-button-svg-hover-color;
        }
      }

      .mui {
        color: $icon-button-svg-hover-color;

        &.submit {
          color: $icon-button-svg-hover-color;
          stroke: $icon-button-svg-hover-color;
          stroke-width: 1px;
        }

        &.revert {
          color: $icon-button-svg-hover-color;
          stroke: $icon-button-svg-hover-color;
          stroke-width: 1px;
        }
      }

      &.save {
        .fill {
          fill: $secondary-color;
        }

        .stroke {
          stroke: $secondary-color;
        }
      }
    }
  }

  .MuiInputBase-root {
    background-color: $input-background-color;

    input {
      text-overflow: ellipsis !important;
    }

    .password-icon {
      .fill {
        @include transition();
        fill: $primary-color,
      }

      .stroke {
        @include transition();
        stroke: $primary-color,
      }
    }

    &.Mui-error {
      .fill {
        fill: $red,
      }

      .stroke {
        stroke: $red,
      }
    }
  }

  .MuiSvgIcon-root {
    @include transition(transform);
  }

  .animating-icon {
    @include transition((color, background-color, box-shadow));

    &.small {
      padding: 0.4rem;

      svg {
        width: 0.7em;
        height: 0.7em;
      }
    }

    &.medium {
      padding: 0.6rem;

      svg {
        width: 1em;
        height: 1em;
      }
    }

    &.large {
      padding: 0.8rem;

      svg {
        width: 1.3em;
        height: 1.3em;
      }
    }

    &.primary {
      @include animating-icon-pulse($primary-color--rgb, 5s);
    }

    &.success {
      @include animating-icon-pulse($green--rgb, 5s);
    }

    &.warning {
      @include animating-icon-pulse($yellow--rgb, 5s);
    }

    &.error {
      @include animating-icon-pulse($red--rgb, 5s);
    }

  }

  .logo {
    svg {
      .fill {
        fill: $primary-color;
      }

      .stroke {
        stroke: $primary-color;
      }
    }
  }

  .app-container {
    color: $primary-text-color;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;

    .app-header {
      --background-color: $default-background-color;

      z-index: 1000;
      padding: 1rem 12px;
      @include transition((background-color, backdrop-filter, box-shadow));
      color: $primary-text-color;
      background-color: var(--background-color);

      .navbar-brand {
        padding-top: 0.2rem;
        padding-bottom: 0;
      }

      .nav-link {
        @include transition((border-bottom-color, color));
        @include rfs($text-sm);
        text-align: center;
        font-weight: 500;
        border-bottom: 3px solid transparent;
        padding: 0.5rem 0.75rem 0.3rem;
        margin: 0 0.5rem;

        &.active {
          border-bottom-color: $secondary-color;
        }
      }

      .navbar-toggler {
        padding: 5px;
        margin-left: 0.5rem;
        border: none;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }

      .logo {
        svg {
          height: 30px;
        }
      }

      .icon-button .custom {
        padding: 0.25rem;
      }

      .searchbar {
        margin: 0 0.5rem;

        .MuiInputBase-root {
          @include transition((background-color, padding-right));
          padding-right: 0;
          background-color: transparent;

          input {
            @include transition((width, padding));
            pointer-events: none;
            margin-left: auto;
            width: 0;
            padding: 0;
          }

          fieldset {
            @include transition((border-color));
            border-color: transparent;
          }

          legend {
            width: 0;
          }
        }

        &.expanded {
          .MuiInputBase-root {
            padding-right: 5px;
            background-color: $input-background-color;

            input {
              pointer-events: auto;
              width: inherit;
              padding: 8.5px 14px;
            }

            fieldset {
              border-color: $primary-color;
            }
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > * {
          margin: 0 0.5rem;
        }
      }
    }

    .app-footer {
      padding: 0.5rem;
      z-index: 1000;
      margin-top: auto;
      width: 100vw;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      .copyright {
        text-align: center;
        width: 100%;
        @include rfs($text-xs);
        color: white;

        p {
          display: inline-block;
        }

        .logo {
          vertical-align: baseline;
          display: inline-block;

          svg {
            margin-left: 5px;
            height: 15px;
            width: 15px;

            .fill {
              fill: white;
            }

            .stroke {
              stroke: white;
            }
          }
        }
      }
    }

    .app {
      flex-grow: 1;

      &.centered {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.error-cursor {
        cursor: none;
      }
    }

    .home-items-container {
      width: 100%;
      margin-bottom: 1rem;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
      }

      .title {
        @include rfs($text-title-md);
        @include font-family(Nunito);
        font-weight: 800;
        margin-top: 0;
        color: $primary-color;
      }

      .content {
        .item {
          margin-top: 1.5rem;
          display: flex;
          justify-content: center;

          .video-card {
            &.medium {
              max-width: 30rem;
            }
          }
        }
      }
    }

    .app-card {
      @include transition((box-shadow, transform), $default-transition-duration);
      background-color: $card-background-color;
      border-radius: 7px;
      position: relative;

      .cover {
        position: relative;

        .play-icon {
          $size: 3rem;
          @include transition((opacity, background-color, color));
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          pointer-events: none;
          background-color: transparent;
          border-radius: 50%;
          width: $size;
          height: $size;
          display: flex;
          align-items: center;
          justify-content: center;

          .fill {
            @include transition(fill);
          }
        }
      }

      &:hover {
        box-shadow: 0 6.65753px 33.2877px $hovered-card-box-shadow-color;
        transform: scale(1.08);
        z-index: 2;

        .play-icon {
          opacity: 1;
          background-color: $video-player-button-background-color;

          .fill {
            fill: $video-player-button-color;
          }
        }
      }
    }

    .course-card {
      &.medium {
        $padding-x: 1.5rem;
        $card-padding: 1rem;

        width: 100%;
        height: 100%;
        padding: $card-padding;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;

        .title {
          @include rfs($text-md);
          width: 100%;
          text-align: start;
          margin-top: 0;
          margin-bottom: 1rem;
          font-weight: 600;
          color: $primary-text-color;
        }

        .cover {
          margin-top: 0;
          width: 100%;
          padding: 0 $padding-x;

          img {
            border-radius: 10px;
            object-fit: cover;
            width: 100%;
            max-width: 100%;
            height: 7rem;
          }
        }

        .course-info {
          width: 100%;
          margin-top: 0.25rem;
          padding: 0 $padding-x;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            @include rfs($text-xs);
            font-weight: 500;
            color: $hint-text-color;

            span {
              color: $primary-text-color;
            }
          }
        }

        .platform {
          @include transition(background-color);
          @include rfs($text-md);
          width: calc(100% - #{$padding-x * 2});
          font-weight: 600;
          margin: (0.5rem + $card-padding) auto 0.5rem;
          padding: 0.5rem;
          border-radius: 5px;
          color: $secondary-color;
          background-color: transparent;
          text-align: center;
          text-transform: capitalize;
        }

        &:hover {
          .platform {
            background-color: rgba($secondary-color--rgb, 0.1);
          }
        }
      }

      &.large {
        $card-padding: 2rem;
        $platform-margin-b: 0.5rem;

        padding: $card-padding;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .content {
          max-width: 45%;
          display: flex;
          flex-direction: column;
        }

        .cover {
          margin-top: 0;
          width: 100%;
          max-width: 55%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 1.5rem;

          img {
            border-radius: 10px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 17.25rem;
          }
        }

        .title {
          @include rfs($text-xl);
          width: 100%;
          text-align: start;
          margin-top: 0;
          margin-bottom: 0.75em;
          font-weight: 500;
          color: $primary-text-color;
        }

        .course-info {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            @include rfs($text-sm);
            font-weight: 500;
            color: $hint-text-color;

            &:first-of-type {
              border-bottom: 2px solid $primary-color;
            }

            span {
              margin-right: 0.25rem;
              @include rfs($text-xl);
              color: $primary-text-color;
            }
          }
        }

        .description {
          margin-top: 1.5rem;
          @include rfs($text-md);
          color: $hint-text-color;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .platform {
          @include transition((background-color, padding));
          @include rfs($text-md);
          width: fit-content;
          font-weight: 600;
          margin: $platform-margin-b 0 $platform-margin-b;
          padding: 0.5rem 0;
          border-radius: 5px;
          color: $secondary-color;
          background-color: transparent;
          text-align: center;
          text-transform: capitalize;
        }

        &:hover {
          .platform {
            background-color: rgba($secondary-color--rgb, 0.1);
            padding: 0.5rem 1rem;
          }
        }
      }
    }

    .video-card {
      $icon-button-padding: 0.5rem;

      &.small {
        $card-padding: 0.75rem;

        @include fade-in-out(
                        '.icon-button.save',
                        '.active',
                        -18%,
                        bottom,
                        $card-padding);
        width: 100%;
        height: 100%;
        padding: $card-padding;
        display: flex;


        .title {
          @include rfs($text-sm);
          width: 100%;
          text-align: start;
          margin: 0;
          font-weight: 600;
          color: $primary-text-color;
        }

        .cover {
          margin-top: 0;
          min-width: 5rem;
          width: 100%;
          max-width: 8rem;
          max-height: 5rem;
          height: 100%;
          padding: 0 1rem 0 0;

          img {
            border-radius: 5px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .video-info {
          display: flex;
          flex-direction: column;
        }

        .date, .duration {
          margin-top: 0.5rem;
          @include rfs($text-xs);
          display: flex;
          align-items: center;
          justify-content: start;
          font-weight: 500;
          color: $hint-text-color;

          .icon {
            fill: $disabled-text-color;
            margin-right: 0.25rem;
          }
        }

        .duration {
          .icon {
            padding: 0.1rem 0 0.1rem 0.1rem;
          }
        }

        &:hover {
          .platform {
            background-color: rgba($secondary-color--rgb, 0.1);
            padding: 0.5rem 1rem;
          }
        }
      }

      &.medium {
        $card-padding: 1rem;
        $platform-margin-b: 0.5rem;

        @include fade-in-out(
                        '.icon-button.save',
                        '.active',
                        calc(-#{$card-padding} - #{$icon-button-padding} + #{math.div($platform-margin-b, 2)}),
                        bottom,
                        $card-padding);
        width: 100%;
        height: 100%;
        padding: $card-padding;
        display: flex;


        .title {
          @include rfs($text-md);
          width: 100%;
          text-align: start;
          margin: 0;
          font-weight: 600;
          color: $primary-text-color;
        }

        .cover {
          margin-top: 0;
          min-width: 11rem;
          width: 11rem;
          max-height: 8rem;
          height: 100%;
          padding: 0 1rem 0 0;

          img {
            border-radius: 10px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .video-info {
          display: flex;
          flex-direction: column;
        }

        .platform {
          @include transition((background-color, padding));
          @include rfs($text-sm - 1px);
          width: fit-content;
          font-weight: 600;
          margin: $platform-margin-b 0 $platform-margin-b;
          padding: 0.5rem 0;
          border-radius: 5px;
          color: $secondary-color;
          background-color: transparent;
          text-align: center;
          text-transform: capitalize;
        }

        .date, .duration {
          margin-top: 0.5rem;
          @include rfs($text-xs);
          display: flex;
          align-items: center;
          justify-content: start;
          font-weight: 500;
          color: $hint-text-color;

          .icon {
            fill: $disabled-text-color;
            margin-right: 0.25rem;
          }
        }

        .duration {
          .icon {
            padding: 0.1rem 0 0.1rem 0.1rem;
          }
        }

        &:hover {
          .platform {
            background-color: rgba($secondary-color--rgb, 0.1);
            padding: 0.5rem 1rem;
          }
        }
      }
    }

    .try-again {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 10rem 0;

      p {
        @include rfs($text-md);
        color: $disabled-text-color;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1rem;
      }
    }

    .video-note-card {
      padding: 1.5rem;

      &:hover {
        transform: none;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .content {

        .input {
          @include transition((background-color, padding));
          padding: 1rem 0;

          legend {
            width: 0;
          }

          fieldset {
            @include transition((border-color));
          }

          &.view-mode {
            padding: 0;
            pointer-events: none;
            background-color: transparent;

            input {
              pointer-events: none;
            }

            fieldset {
              border-color: transparent;
            }
          }
        }
      }
    }
  }

  .video-container-layout {
    position: relative;
    @include transition();

    &.active:not(&.large) {
      margin-bottom: 26px;
    }

    .video-container {
      border-radius: 10px;
      position: relative;

      iframe, video {
        width: 100%;
        border-radius: 10px;
        cursor: pointer;
      }

      .play-button-container {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 1;

        .play-button {
          $size: 4rem;

          @include transition();
          width: $size;
          height: $size;
          border: none;
          background-color: $video-player-button-background-color;
          border-radius: 100%;

          svg {
            backdrop-filter: blur(5px);
            height: 50%;
            width: 50%;

            path {
              fill: $video-player-button-color;
            }
          }

          &:hover {
            $size: 5rem;

            background-color: $video-player-button-hover-background-color;
            width: $size;
            height: $size;

            svg {
              path {
                fill: $video-player-button-hover-color;
              }
            }
          }
        }
      }
    }

    .video-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &.small {
        position: absolute;
        bottom: -25px;

        .inner {
          width: 95%;
        }

      }

      &.large {
        .inner {
          width: 100%;
          position: absolute;
          bottom: 0;
          padding-bottom: 5px;

          .progress {
            margin: 0 10px;
          }

          .video-icon {
            width: 36px;
            height: 30px;
            cursor: pointer;
            margin: 5px 7px;
            @include transition();
            background-color: $video-player-button-background-color;
            border-radius: 100%;
            padding: 4px;

            path {
              @include transition();
              fill: $video-player-button-color;
            }

            &:hover {
              transform: scale(1.2);
              background-color: $video-player-button-hover-background-color;

              path {
                fill: $video-player-button-hover-color;
              }
            }
          }
        }
      }

      .inner {
        border-radius: 7px;
        padding: 12px 5px 2px 5px;
        backdrop-filter: blur(9px);
        background-color: rgba($primary-color--rgb, 0.1);
        display: flex;
        align-items: center;
        width: 100%;

        .progress {
          width: 100%;
          height: 7px;
          margin: 0 10px;

          &::-webkit-progress-bar {
            cursor: pointer;
            height: 7px;
            border-radius: 3px;
            background-color: $video-player-progressbar-color;
          }

          &::-webkit-progress-inner-element {
            height: 7px;
            border-radius: 3px;
            background-color: $video-player-progressbar-color;
          }

          &::-webkit-progress-value {
            height: 7px;
            border-radius: 3px;
            background-color: $video-player-progress-value-color;
          }

        }

        .video-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
          margin: 5px 7px;
          fill: $video-player-button-color;
          @include transition();

          &:hover {
            transform: scale(1.4);
          }
        }
      }
    }
  }

  .MuiTab-root {
    @include transition(color);
  }

}

// Search Sections

html {
  .content-search-section {
    background-color: $search-background-color;
    padding-top: 3rem;

    .spacer {
      padding-bottom: 3rem;
    }

    .title {
      @include underline();
      @include rfs($text-title-md);
      @include font-family(Nunito);
      width: fit-content;
      font-weight: 800;
      color: $primary-color;
      margin-bottom: 3rem;
    }

    .svg-container {
      position: relative;

      .right-svg {
        position: absolute;
        height: 100%;
        max-width: 100%;
        opacity: 0.4;
      }

      &.all {
        .right-svg {
          bottom: -0.7rem;
        }
      }
    }

    .button[type='submit'] {
      min-width: css-min(15rem, 100%);
      min-height: 2.5rem !important;
    }
  }
}

// Popovers

.apps-popover {
  padding: 20px 0;
  min-width: css-min(90vw, 300px) !important;
  max-width: css-min(90vw, 300px) !important;

  .content {
    .title {
      @include transition();
      @include rfs($text-sm);
      font-weight: 600;
    }

    .app {
      @include transition();
      @include rfs($text-xs);
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 1rem;

      img {
        $size: 35px;
        @include center-image();
        border: 1px solid var(--color);
        margin-right: 0.5rem;
        border-radius: 3px;
        width: $size;
        height: $size;
      }

      color: var(--color);
      font-weight: 600;

      &:hover {
        background-color: rgba($primary-color--rgb, 0.5);
      }
    }
  }
}

// Dialogs

.app-dialog {
  .title {
    @include rfs($text-xl);
    font-weight: 600;
    color: $primary-text-color;
  }

  .content {
    padding: 2rem 1.5rem 4rem;
  }
}

// Views of the application

.error-view {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  * {
    cursor: none !important;
  }

  .foreground {
    position: fixed;
    pointer-events: none;
    z-index: 999;
    width: 200vw;
    height: 200vh;
    left: -100vw;
    top: -100vh;
    transform-origin: center;
    mask-image: radial-gradient(15vw at 50% 50%, transparent 100%, black 100%);
    //noinspection CssInvalidPropertyValue
    -webkit-mask-image: radial-gradient(15vw at 50% 50%, transparent 100%, black 100%);
  }

  .flashlight {
    position: absolute;
    pointer-events: none;
    z-index: 999;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }

  @supports not ((backdrop-filter: blur(0px)) or (-webkit-backdrop-filter: blur(0px))) {
    .foreground {
      background-color: rgba($default-background-color--rgb, .8);
    }
  }

  @media screen and (min-width: $md) {
    .foreground {
      backdrop-filter: blur(3px);
    }
  }


  h1 {
    @include font-family(Nunito);
    @include transition();
    font-size: 12rem;
    letter-spacing: 10px;
    margin: 0;
    font-weight: 700;
    color: $primary-color;

    span {
      @include transition();
      color: $primary-color;
      text-shadow: 1px 1px 0 $primary-color,
      -1px -1px 0px rgba($primary-color--rgb, 0.5),
      0px 0px 4px rgba($primary-color--rgb, 0.8);
    }
  }

  p {
    text-align: center;
    @include rfs($text-sm);
    margin: 2rem;
  }

}

.auth-view {
  height: 100%;

  .loading-container {
    max-height: 50vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100vw;
      margin: auto;
      object-fit: contain;
    }
  }

  P {
    @include rfs($text-lg);
    color: $hint-text-color;
    text-align: center;

    span {
      @include rfs($text-xl);
      color: $primary-color;
      font-weight: 600;
    }

    &:not(&:first-of-type) {
      @include rfs($text-md);
    }
  }

}

.private-view {
}

.home-view {

  .hero {
    .spacer {
      width: 100%;
      height: 5rem;
    }

    .spacer-bottom {
      margin-top: 4rem;
    }

    .title {
      @include rfs($text-title-xxl);
      @include font-family(Nunito);
      text-align: start;
      font-weight: 800;
      color: $primary-color;

      span {
        @include underline('right', -15px, -30px, 15rem, 30px, 357deg);
      }
    }

    .left-svg {
      width: 100%;
      height: fit-content;
    }

    .right-svg {
      height: fit-content;
    }
  }

  .future {
    overflow-x: hidden;
    margin-top: 3rem;
    padding-bottom: 3rem;
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
    background-color: $home-view-future-background-color;

    $margin-top: 4vw;
    $width: 100vw;
    $left-side: 15vw;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-right-color: transparent;
      border-left-color: transparent;
      border-top-color: $default-background-color;
      border-right-style: solid;
      border-left-style: solid;
      border-top-style: solid;
      border-left-width: calc(#{$left-side});
      border-right-width: calc(#{$width} - #{$left-side});
      border-top-width: $margin-top;
    }

    .spacer {
      margin-top: $margin-top;
    }

    .title {
      @include rfs($text-title-xxl);
      @include font-family(Nunito);
      text-align: start;
      font-weight: 800;
      color: $home-view-future-color;
      margin-top: 2rem;
    }

    .content {
      @include rfs($text-sm);
      font-weight: 500;
      padding: 0 0.8rem;
      margin-top: 1.5rem;
      color: $home-view-future-color;
    }

    .left-svg {
      width: 100%;
      margin-top: 2rem;
      height: 20rem;
    }

    .right-svg {
      max-width: 100%;
    }

    .content-container {
      position: relative;

      .title {
        position: absolute;
        top: 0;
        left: 5rem;
      }


      .right-svg {
        margin-top: 2rem;
        max-height: 40rem;
      }
    }

    .spacer-bottom {
      margin-top: 5rem;
    }

    .home-items-container {
      .header {
        justify-content: center;
        margin-bottom: 4rem;

        > *:first-child {
          @include underline();
          @include rfs($text-title-lg);
          margin-right: 1rem;
          color: $home-view-future-color;
          position: relative;
        }
      }
    }
  }

  .entries {
    margin-top: 4rem;
    margin-bottom: 5rem;
  }
}

.search-view,
.courses-list-view,
.videos-list-view {

  .spacer {
    margin-top: 5rem;
  }

  .item {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .video-card {
      &.medium {
        max-width: 35rem;
      }
    }
  }
}

.course-information-view {

  .cover {
    margin-top: 2rem;
    position: relative;
    width: 100%;
    height: 30rem;
    border-radius: 10px;

    img {
      border-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 10rem;
      display: flex;
      flex-direction: column;
      background-color: $primary-color;
      padding: 0;
      border-radius: 10px 10px 0 0;

      .title {
        padding: 1.5rem;
        color: $primary-text-contrast-color;

        > p {
          @include rfs($text-title-xl);
          @include font-family(Nunito);
          font-weight: 800;
          display: inline-block;

          .underline {
            margin: 0 0.5rem;
            position: relative;
            width: fit-content;
            display: inline-block;

            .inner {
              position: absolute;
              bottom: -24px;
              left: 0;
              height: 24px;
              width: 100%;
              border-radius: 50%;
              transform: rotate(3deg);
              background-size: 100vw;
              z-index: 1;
            }

            .inner-bg {
              position: absolute;
              bottom: -46px;
              left: -15%;
              height: 25px;
              width: 125%;
              border: 20px solid $primary-color;
              border-radius: 50%;
              transform: rotate(3deg);
              z-index: 2;
            }
          }
        }
      }

      .spacer {
        flex: 1 1 auto;
        min-height: 4rem;
        background-color: $primary-color;
      }

      .course-info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: start;
        padding: 1rem 1rem;
        background-color: $primary-color-dark;

        > div {
          display: flex;
          align-items: center;
          width: fit-content;
          padding: 0 1rem;
          margin: 0.5rem 0;
        }

        > div:last-of-type {
          flex: 1 1 auto;
          justify-content: flex-end;
        }

        span {
          @include rfs($text-title-sm);
          color: $primary-text-contrast-color;
          margin-right: 0.25rem;
          font-weight: 600;
        }

        p {
          @include rfs($text-md + 1);
          color: $primary-text-contrast-color;
          vertical-align: baseline;
          margin-top: 0.3rem;

          &:first-letter {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

      }
    }
  }

  .content {
    @include rfs($text-sm);
    font-weight: 400;
    margin: 2rem 1.5rem;
    color: $secondary-text-color;
  }

  .videos-title {
    @include rfs($text-title-md);
    font-weight: 700;
    color: $primary-text-color;
    margin: 1rem 0 3rem;
  }

  .spacer {
    margin-top: 4rem;
  }
}

.video-information-view {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
  }

  .video-player {
    height: css-min(50vw, 70vh);

    .video-container-layout {
      height: 100%;

      .video-container {
        height: 100%;

        iframe, video {
          height: 100%;
        }
      }
    }
  }

  .playlist-container {

    .playlist-title {
      position: sticky;
      top: 0;
      padding: 2rem 0 1rem;
      z-index: 1;
      background-color: $default-background-color;

      p {
        @include underline();
        @include rfs($text-title-xs);
        font-weight: 700;
        width: fit-content;
      }
    }

    .playlist {
      padding: 1.5rem 1rem 3rem;

      .item {
        margin-bottom: 1rem;

        .video-card {

          &:hover {
            z-index: 0;
            transform: scale(1.01);
          }

          &.active {
            pointer-events: none;
            background-color: rgba($primary-color--rgb, 0.2);

            .icon-button.save {
              display: none;
            }
          }
        }

      }

      &::-webkit-scrollbar {
        transition: none;
        width: 8px !important;
        height: 8px;
        margin: 0 5px;
      }

      &::-webkit-scrollbar-track {
        background: $scrollbar-track-color;
        border-radius: 3px;
      }


      &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
        border-radius: 3px;
        cursor: pointer;
      }

    }
  }

  .info {

    > div {
      display: flex;
      align-items: start;
      justify-content: space-between;
    }

    > div:first-of-type {
      position: sticky;
      top: 0;
      padding: 2rem 0 0;
      z-index: 100;
      background-color: $default-background-color;
    }

    > div:nth-of-type(2) {
      padding-top: 0.5rem;
      align-items: center;
    }

    .title {
      padding-top: 0.25rem;
      @include rfs($text-title-xs);
      font-weight: 700;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      * > {
        margin-left: 1rem;
      }
    }

    .course {
      display: flex;
      align-items: center;
      justify-content: start;
      color: $primary-text-color;

      svg {
        @include transition(color);
      }

      p {
        @include transition(color text-decoration-color);
        @include rfs($text-sm);
        font-weight: 500;
        margin-left: 0.1rem;
        text-decoration: 1px underline $disabled-text-color;
      }

      &:hover {
        color: $secondary-color;

        p {
          text-decoration-color: $secondary-color;
        }
      }
    }

    .spacer {
      margin-top: 1.5rem;
    }

    section {
      .description, .caption {
        padding: 2rem 1.5rem;
        @include rfs($text-sm);
        color: $secondary-text-color;
      }

      .notebook {
        padding: 2rem 0 0;

        .create-video-note-form {
          padding: 0 1rem;

          .col-12 {
            padding: 0;
          }
        }

        .notes-title {
          display: flex;
          align-items: center;
          justify-content: start;
          padding-top: 2rem;
          position: sticky;
          top: 0;
          z-index: 100;
          background-color: $default-background-color;

          p {
            @include rfs($text-title-xs);
            color: $primary-text-color;
            font-weight: 700;
            margin-right: 0.25rem;
          }
        }

        .notes-container {
          padding: 2rem 0 2rem;

          .item {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
