/*!
 *
 */

@import "../mixins/keyframes";

@include keyframes(placeholder-shimmer) {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
