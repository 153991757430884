/*!
 *
 */

@use 'sass:math';
@use 'sass:list';
@use 'sass:map';
@import "../variables/index";

// **************** SPACE GENERATION ****************


@each $bp in ['', ("xs", $xs), ("sm", $sm), ('md',$md), ('lg',$lg),('xl',$xl), ('xxl',$xxl)] {
  @if ($bp == '') {
    .w-auto {
      width: auto !important;
    }

    .h-auto {
      height: auto !important;
    }

    .min-w-auto {
      min-width: auto !important;
    }

    .min-h-auto {
      min-height: auto !important;
    }

    .min-vh-auto {
      min-height: auto !important;
    }

    .min-vw-auto {
      min-width: auto !important;
    }

    @for $value from 0 to 101 {
      .w-#{$value} {
        width: percentage(math.div($value, 100)) !important;
      }

      .h-#{$value} {
        height: percentage(math.div($value, 100)) !important;
      }

      .min-w-#{$value} {
        min-width: percentage(math.div($value, 100)) !important;
      }

      .min-h-#{$value} {
        min-height: percentage(math.div($value, 100)) !important;
      }

      .max-w-#{$value} {
        max-width: percentage(math.div($value, 100)) !important;
      }

      .max-h-#{$value} {
        max-height: percentage(math.div($value, 100)) !important;
      }

      .min-vh-#{$value} {
        min-height: #{$value}vh !important;
      }

      .min-vw-#{$value} {
        min-width: #{$value}vw !important;
      }

      .flex-grow-#{$value} {
        flex-grow: $value !important;
      }
    }

  } @else {
    $bp-name: list.nth($bp, 1);
    $bp-value: list.nth($bp, 2);

    @media screen and (min-width: $bp-value) {

      .w-#{$bp-name}-auto {
        width: auto !important;
      }

      .h-#{$bp-name}-auto {
        height: auto !important;
      }

      .min-vh-#{$bp-name}-auto {
        min-height: auto !important;
      }

      .min-vw-#{$bp-name}-auto {
        min-width: auto !important;
      }

      @for $value from 0 to 101 {
        .w-#{$bp-name}-#{$value} {
          width: percentage(math.div($value, 100)) !important;
        }

        .h-#{$bp-name}-#{$value} {
          height: percentage(math.div($value, 100)) !important;
        }

        .min-w-#{$bp-name}-#{$value} {
          min-width: percentage(math.div($value, 100)) !important;
        }

        .min-h-#{$bp-name}-#{$value} {
          min-height: percentage(math.div($value, 100)) !important;
        }

        .max-w-#{$bp-name}-#{$value} {
          max-width: percentage(math.div($value, 100)) !important;
        }

        .max-h-#{$bp-name}-#{$value} {
          max-height: percentage(math.div($value, 100)) !important;
        }

        .min-vh-#{$bp-name}-#{$value} {
          min-height: #{$value}vh !important;
        }

        .min-vw-#{$bp-name}-#{$value} {
          min-width: #{$value}vw !important;
        }

        .flex-grow-#{$bp-name}-#{$value} {
          flex-grow: $value !important;
        }
      }
    }
  }

}

// **************** STATIC SPACES ****************


.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}
