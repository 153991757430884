/*!
 *
 */
@use "sass:math";
@import "./variables";
@import "./mixins";


@include safari-only {

  // Search Sections
  html {
    .content-search-section {
      .title {
        @include safari-underline($search-background-color);
      }
    }
  }

  // Views

  .home-view {

    .hero {
      .left-svg {
        height: 100%;
        z-index: 3;
      }

      .right-svg {
        height: 100%;
        z-index: 3;
      }

      .title {
        span {
          @include safari-underline($default-background-color, 'right', -15px, -30px, 15rem, 30px, 357deg);
        }
      }
    }

    .future {
      .home-items-container {
        .header {
          > *:first-child {
            @include safari-underline($home-view-future-background-color);
          }
        }
      }
    }
  }

  .video-information-view {
    .playlist-container {
      .playlist-title {
        p {
          @include safari-underline($default-background-color);
        }
      }
    }
  }

  @media screen and (min-width: $lg) {
    .video-information-view {
      .playlist-container {
        .playlist-title {
          margin-bottom: 1rem;

          p {
            @include safari-underline($video-playlist-background-color);
          }
        }
      }
    }
  }
}

