/*!
 *
 */

@use 'sass:list';
@import "../variables";

@mixin transition($properties: all, $duration: $default-transition-duration,$easing-function: ease-in-out, $important: false) {
  $transition: ();
  @if (type-of($properties) ==  list) {
    $transition: ();
    @each $property in $properties {
      $transition: $transition, $property $duration $easing-function;
    }
  } @else {
    $transition: ($properties $duration $easing-function);
  }
  @if ($important) {
    -webkit-transition: $transition !important;
    -moz-transition: $transition !important;
    -o-transition: $transition !important;
    transition: $transition !important;
  } @else {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
  }
}
