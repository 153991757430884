/*!
 *
 */

// ROUTE_FADE
.route-fade-enter {
  opacity: 0;
  z-index: 1;
}

.route-fade-enter.route-fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.route-fade-exit {
  opacity: 0;
  transition: opacity 0ms linear;
}


// SLIDING
.slide-enter {
  opacity: 0;
  transform: translateX(500px) scale(0.6);
}

.slide-enter-active {
  opacity: 1;
  transition: all 400ms ease-out;
  transform: translateX(0) scale(1);
}

.slide-exit {
  opacity: 1;
}

.slide-exit-active {
  opacity: 0;
  transition: all 400ms ease-in;
  transform: translateX(-500px) scale(0.6);
}
