/*!
 *
 */

// Safari Only selector. Works on Safari 11+
// more info on [https://browserstrangeness.github.io/css_hacks.html#safari]
@mixin safari-only() {
  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      @content;
    }
  }
}
