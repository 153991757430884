/*!
 *
 */

@import "variables";
@import "extensions";
@import "mixins";
@import "functions";


html {

  // XS
  @media screen and (min-width: $xs) {
    .app {
      .app-header {
        .searchbar {
          margin: 0;
        }
      }
    }

    .home-view {
      .hero {
        .left-svg {
          width: fit-content;
          max-width: 60%;
        }

        .right-svg {
          max-width: 30vw;
        }
      }
    }
  }

  // SM
  @media screen and (min-width: $sm) {
    .app {
      .app-header {
        .searchbar {
          margin: 0 0.5rem;
          max-width: 200px;

        }
      }
    }
  }

  // MD
  @media screen and (min-width: $md) {
    .course-information-view {
      .cover {
        .overlay {
          width: 40%;
          height: 30rem;
          border-radius: 10px 0 0 10px;

          .course-info {
            border-bottom-left-radius: 10px;
          }
        }
      }
    }

    .video-information-view {
      .playlist-container {
        .playlist {
          padding: 1.5rem 1rem 3rem;
        }
      }
    }
  }

  // LG
  @media screen and (min-width: $lg) {
    .app {
      .app-header {
        .searchbar.expanded {
          input {
            max-width: unset;
          }
        }
      }
    }

    .home-view {
      .hero {
        .left-svg {
          max-width: 100%;
        }

        .right-svg {
          max-width: 18vw;
        }
      }

      .future {
        .content-container {
          .content {
            @include rfs($text-sm);
            position: absolute;
            top: 15rem;
            right: 12rem;
            width: 20rem;
          }
        }
      }
    }

    .video-information-view {

      &.belongs-to-course {
        &:before {
          height: 100%;
          width: 42vw;
          background-color: $video-playlist-background-color;
        }
      }


      .playlist-container {
        position: sticky;
        top: 0;
        height: fit-content;

        .playlist-title {
          background-color: $video-playlist-background-color;
        }

        .playlist {
          padding: 1.5rem 0 3rem;
          background-color: $video-playlist-background-color;
          max-height: 80vh;
          overflow-y: auto;
          overflow-x: hidden;

          .item {
            .video-card {
              .date {
                display: none;
              }
            }
          }
        }
      }

      .info {
        position: sticky;
        top: 0;
        height: fit-content;
      }

    }
  }

  // XL
  @media screen and (min-width: $xl) {
    .home-view {
      .future {
        .content-container {
          .content {
            right: 15rem;
            width: 25rem;
          }
        }
      }
    }

    .content-search-section {
      .svg-container {
        .right-svg {
          bottom: 0;
        }
      }
    }

    .course-card {
      &.large {
        .cover {
          img {
            max-height: 22rem !important;
          }
        }

        .description {
          -webkit-line-clamp: 6 !important;
          line-clamp: 6 !important;
        }
      }
    }

    .video-information-view {
      .playlist-container {
        .playlist {
          padding: 1.5rem 1rem 3rem;

          .item {
            .video-card {
              .date {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  // XXL
  @media screen and (min-width: $xxl) {
    .home-view {
      .future {
        .content-container {
          .content {
            right: 20rem;
          }
        }
      }
    }
  }
}
