/*!
 *
 */

// **************** APPLICATION DYNAMIC COLORS ****************
// This output is autogenerated. It is strongly recommended not to change the following declarations.

$color-names: (
        'primary-color': var(--app-primary-color),
        'primary-color--rgb': var(--app-primary-color--rgb),
        'primary-color--rgba': var(--app-primary-color--rgba),
        'primary-color-dark': var(--app-primary-color-dark),
        'primary-color-dark--rgb': var(--app-primary-color-dark--rgb),
        'primary-color-dark--rgba': var(--app-primary-color-dark--rgba),
        'secondary-color': var(--app-secondary-color),
        'secondary-color--rgb': var(--app-secondary-color--rgb),
        'secondary-color--rgba': var(--app-secondary-color--rgba),
        'red': var(--app-red),
        'red--rgb': var(--app-red--rgb),
        'red--rgba': var(--app-red--rgba),
        'red-dark': var(--app-red-dark),
        'red-dark--rgb': var(--app-red-dark--rgb),
        'red-dark--rgba': var(--app-red-dark--rgba),
        'green': var(--app-green),
        'green--rgb': var(--app-green--rgb),
        'green--rgba': var(--app-green--rgba),
        'yellow': var(--app-yellow),
        'yellow--rgb': var(--app-yellow--rgb),
        'yellow--rgba': var(--app-yellow--rgba),
        'white': var(--app-white),
        'white--rgb': var(--app-white--rgb),
        'white--rgba': var(--app-white--rgba),
        'black': var(--app-black),
        'black--rgb': var(--app-black--rgb),
        'black--rgba': var(--app-black--rgba),
        'disabled-button-background-color': var(--app-disabled-button-background-color),
        'disabled-button-background-color--rgb': var(--app-disabled-button-background-color--rgb),
        'disabled-button-background-color--rgba': var(--app-disabled-button-background-color--rgba),
        'primary-text-color': var(--app-primary-text-color),
        'primary-text-color--rgb': var(--app-primary-text-color--rgb),
        'primary-text-color--rgba': var(--app-primary-text-color--rgba),
        'primary-text-contrast-color': var(--app-primary-text-contrast-color),
        'primary-text-contrast-color--rgb': var(--app-primary-text-contrast-color--rgb),
        'primary-text-contrast-color--rgba': var(--app-primary-text-contrast-color--rgba),
        'secondary-text-color': var(--app-secondary-text-color),
        'secondary-text-color--rgb': var(--app-secondary-text-color--rgb),
        'secondary-text-color--rgba': var(--app-secondary-text-color--rgba),
        'disabled-text-color': var(--app-disabled-text-color),
        'disabled-text-color--rgb': var(--app-disabled-text-color--rgb),
        'disabled-text-color--rgba': var(--app-disabled-text-color--rgba),
        'hint-text-color': var(--app-hint-text-color),
        'hint-text-color--rgb': var(--app-hint-text-color--rgb),
        'hint-text-color--rgba': var(--app-hint-text-color--rgba),
        'default-background-color': var(--app-default-background-color),
        'default-background-color--rgb': var(--app-default-background-color--rgb),
        'default-background-color--rgba': var(--app-default-background-color--rgba),
        'video-playlist-background-color': var(--app-video-playlist-background-color),
        'video-playlist-background-color--rgb': var(--app-video-playlist-background-color--rgb),
        'video-playlist-background-color--rgba': var(--app-video-playlist-background-color--rgba),
        'search-background-color': var(--app-search-background-color),
        'search-background-color--rgb': var(--app-search-background-color--rgb),
        'search-background-color--rgba': var(--app-search-background-color--rgba),
        'home-view-future-background-color': var(--app-home-view-future-background-color),
        'home-view-future-background-color--rgb': var(--app-home-view-future-background-color--rgb),
        'home-view-future-background-color--rgba': var(--app-home-view-future-background-color--rgba),
        'home-view-future-color': var(--app-home-view-future-color),
        'home-view-future-color--rgb': var(--app-home-view-future-color--rgb),
        'home-view-future-color--rgba': var(--app-home-view-future-color--rgba),
        'footer-background-color': var(--app-footer-background-color),
        'footer-background-color--rgb': var(--app-footer-background-color--rgb),
        'footer-background-color--rgba': var(--app-footer-background-color--rgba),
        'footer-color': var(--app-footer-color),
        'footer-color--rgb': var(--app-footer-color--rgb),
        'footer-color--rgba': var(--app-footer-color--rgba),
        'input-background-color': var(--app-input-background-color),
        'input-background-color--rgb': var(--app-input-background-color--rgb),
        'input-background-color--rgba': var(--app-input-background-color--rgba),
        'card-background-color': var(--app-card-background-color),
        'card-background-color--rgb': var(--app-card-background-color--rgb),
        'card-background-color--rgba': var(--app-card-background-color--rgba),
        'default-box-shadow-color': var(--app-default-box-shadow-color),
        'default-box-shadow-color--rgb': var(--app-default-box-shadow-color--rgb),
        'default-box-shadow-color--rgba': var(--app-default-box-shadow-color--rgba),
        'hovered-card-box-shadow-color': var(--app-hovered-card-box-shadow-color),
        'hovered-card-box-shadow-color--rgb': var(--app-hovered-card-box-shadow-color--rgb),
        'hovered-card-box-shadow-color--rgba': var(--app-hovered-card-box-shadow-color--rgba),
        'contained-button-box-shadow-color': var(--app-contained-button-box-shadow-color),
        'contained-button-box-shadow-color--rgb': var(--app-contained-button-box-shadow-color--rgb),
        'contained-button-box-shadow-color--rgba': var(--app-contained-button-box-shadow-color--rgba),
        'icon-button-svg-color': var(--app-icon-button-svg-color),
        'icon-button-svg-color--rgb': var(--app-icon-button-svg-color--rgb),
        'icon-button-svg-color--rgba': var(--app-icon-button-svg-color--rgba),
        'icon-button-svg-hover-color': var(--app-icon-button-svg-hover-color),
        'icon-button-svg-hover-color--rgb': var(--app-icon-button-svg-hover-color--rgb),
        'icon-button-svg-hover-color--rgba': var(--app-icon-button-svg-hover-color--rgba),
        'button-primary-background-color': var(--app-button-primary-background-color),
        'button-primary-background-color--rgb': var(--app-button-primary-background-color--rgb),
        'button-primary-background-color--rgba': var(--app-button-primary-background-color--rgba),
        'button-primary-color': var(--app-button-primary-color),
        'button-primary-color--rgb': var(--app-button-primary-color--rgb),
        'button-primary-color--rgba': var(--app-button-primary-color--rgba),
        'button-secondary-background-color': var(--app-button-secondary-background-color),
        'button-secondary-background-color--rgb': var(--app-button-secondary-background-color--rgb),
        'button-secondary-background-color--rgba': var(--app-button-secondary-background-color--rgba),
        'button-secondary-color': var(--app-button-secondary-color),
        'button-secondary-color--rgb': var(--app-button-secondary-color--rgb),
        'button-secondary-color--rgba': var(--app-button-secondary-color--rgba),
        'video-player-button-color': var(--app-video-player-button-color),
        'video-player-button-color--rgb': var(--app-video-player-button-color--rgb),
        'video-player-button-color--rgba': var(--app-video-player-button-color--rgba),
        'video-player-button-background-color': var(--app-video-player-button-background-color),
        'video-player-button-background-color--rgb': var(--app-video-player-button-background-color--rgb),
        'video-player-button-background-color--rgba': var(--app-video-player-button-background-color--rgba),
        'video-player-button-hover-color': var(--app-video-player-button-hover-color),
        'video-player-button-hover-color--rgb': var(--app-video-player-button-hover-color--rgb),
        'video-player-button-hover-color--rgba': var(--app-video-player-button-hover-color--rgba),
        'video-player-button-hover-background-color': var(--app-video-player-button-hover-background-color),
        'video-player-button-hover-background-color--rgb': var(--app-video-player-button-hover-background-color--rgb),
        'video-player-button-hover-background-color--rgba': var(--app-video-player-button-hover-background-color--rgba),
        'video-player-progress-value-color': var(--app-video-player-progress-value-color),
        'video-player-progress-value-color--rgb': var(--app-video-player-progress-value-color--rgb),
        'video-player-progress-value-color--rgba': var(--app-video-player-progress-value-color--rgba),
        'video-player-progressbar-color': var(--app-video-player-progressbar-color),
        'video-player-progressbar-color--rgb': var(--app-video-player-progressbar-color--rgb),
        'video-player-progressbar-color--rgba': var(--app-video-player-progressbar-color--rgba),
        'scrollbar-track-color': var(--app-scrollbar-track-color),
        'scrollbar-track-color--rgb': var(--app-scrollbar-track-color--rgb),
        'scrollbar-track-color--rgba': var(--app-scrollbar-track-color--rgba),
        'scrollbar-thumb-color': var(--app-scrollbar-thumb-color),
        'scrollbar-thumb-color--rgb': var(--app-scrollbar-thumb-color--rgb),
        'scrollbar-thumb-color--rgba': var(--app-scrollbar-thumb-color--rgba),
);

// **************** APPLICATION COLORS ****************
// This output is autogenerated. It is strongly recommended not to change the following declarations.

$primary-color: var(--app-primary-color);
$primary-color--rgb: var(--app-primary-color--rgb);
$primary-color--rgba: var(--app-primary-color--rgba);

$primary-color-dark: var(--app-primary-color-dark);
$primary-color-dark--rgb: var(--app-primary-color-dark--rgb);
$primary-color-dark--rgba: var(--app-primary-color-dark--rgba);

$secondary-color: var(--app-secondary-color);
$secondary-color--rgb: var(--app-secondary-color--rgb);
$secondary-color--rgba: var(--app-secondary-color--rgba);

$red: var(--app-red);
$red--rgb: var(--app-red--rgb);
$red--rgba: var(--app-red--rgba);

$red-dark: var(--app-red-dark);
$red-dark--rgb: var(--app-red-dark--rgb);
$red-dark--rgba: var(--app-red-dark--rgba);

$green: var(--app-green);
$green--rgb: var(--app-green--rgb);
$green--rgba: var(--app-green--rgba);

$yellow: var(--app-yellow);
$yellow--rgb: var(--app-yellow--rgb);
$yellow--rgba: var(--app-yellow--rgba);

$white: var(--app-white);
$white--rgb: var(--app-white--rgb);
$white--rgba: var(--app-white--rgba);

$black: var(--app-black);
$black--rgb: var(--app-black--rgb);
$black--rgba: var(--app-black--rgba);

$disabled-button-background-color: var(--app-disabled-button-background-color);
$disabled-button-background-color--rgb: var(--app-disabled-button-background-color--rgb);
$disabled-button-background-color--rgba: var(--app-disabled-button-background-color--rgba);

$primary-text-color: var(--app-primary-text-color);
$primary-text-color--rgb: var(--app-primary-text-color--rgb);
$primary-text-color--rgba: var(--app-primary-text-color--rgba);

$primary-text-contrast-color: var(--app-primary-text-contrast-color);
$primary-text-contrast-color--rgb: var(--app-primary-text-contrast-color--rgb);
$primary-text-contrast-color--rgba: var(--app-primary-text-contrast-color--rgba);

$secondary-text-color: var(--app-secondary-text-color);
$secondary-text-color--rgb: var(--app-secondary-text-color--rgb);
$secondary-text-color--rgba: var(--app-secondary-text-color--rgba);

$disabled-text-color: var(--app-disabled-text-color);
$disabled-text-color--rgb: var(--app-disabled-text-color--rgb);
$disabled-text-color--rgba: var(--app-disabled-text-color--rgba);

$hint-text-color: var(--app-hint-text-color);
$hint-text-color--rgb: var(--app-hint-text-color--rgb);
$hint-text-color--rgba: var(--app-hint-text-color--rgba);

$default-background-color: var(--app-default-background-color);
$default-background-color--rgb: var(--app-default-background-color--rgb);
$default-background-color--rgba: var(--app-default-background-color--rgba);

$video-playlist-background-color: var(--app-video-playlist-background-color);
$video-playlist-background-color--rgb: var(--app-video-playlist-background-color--rgb);
$video-playlist-background-color--rgba: var(--app-video-playlist-background-color--rgba);

$search-background-color: var(--app-search-background-color);
$search-background-color--rgb: var(--app-search-background-color--rgb);
$search-background-color--rgba: var(--app-search-background-color--rgba);

$home-view-future-background-color: var(--app-home-view-future-background-color);
$home-view-future-background-color--rgb: var(--app-home-view-future-background-color--rgb);
$home-view-future-background-color--rgba: var(--app-home-view-future-background-color--rgba);

$home-view-future-color: var(--app-home-view-future-color);
$home-view-future-color--rgb: var(--app-home-view-future-color--rgb);
$home-view-future-color--rgba: var(--app-home-view-future-color--rgba);

$footer-background-color: var(--app-footer-background-color);
$footer-background-color--rgb: var(--app-footer-background-color--rgb);
$footer-background-color--rgba: var(--app-footer-background-color--rgba);

$footer-color: var(--app-footer-color);
$footer-color--rgb: var(--app-footer-color--rgb);
$footer-color--rgba: var(--app-footer-color--rgba);

$input-background-color: var(--app-input-background-color);
$input-background-color--rgb: var(--app-input-background-color--rgb);
$input-background-color--rgba: var(--app-input-background-color--rgba);

$card-background-color: var(--app-card-background-color);
$card-background-color--rgb: var(--app-card-background-color--rgb);
$card-background-color--rgba: var(--app-card-background-color--rgba);

$default-box-shadow-color: var(--app-default-box-shadow-color);
$default-box-shadow-color--rgb: var(--app-default-box-shadow-color--rgb);
$default-box-shadow-color--rgba: var(--app-default-box-shadow-color--rgba);

$hovered-card-box-shadow-color: var(--app-hovered-card-box-shadow-color);
$hovered-card-box-shadow-color--rgb: var(--app-hovered-card-box-shadow-color--rgb);
$hovered-card-box-shadow-color--rgba: var(--app-hovered-card-box-shadow-color--rgba);

$contained-button-box-shadow-color: var(--app-contained-button-box-shadow-color);
$contained-button-box-shadow-color--rgb: var(--app-contained-button-box-shadow-color--rgb);
$contained-button-box-shadow-color--rgba: var(--app-contained-button-box-shadow-color--rgba);

$icon-button-svg-color: var(--app-icon-button-svg-color);
$icon-button-svg-color--rgb: var(--app-icon-button-svg-color--rgb);
$icon-button-svg-color--rgba: var(--app-icon-button-svg-color--rgba);

$icon-button-svg-hover-color: var(--app-icon-button-svg-hover-color);
$icon-button-svg-hover-color--rgb: var(--app-icon-button-svg-hover-color--rgb);
$icon-button-svg-hover-color--rgba: var(--app-icon-button-svg-hover-color--rgba);

$button-primary-background-color: var(--app-button-primary-background-color);
$button-primary-background-color--rgb: var(--app-button-primary-background-color--rgb);
$button-primary-background-color--rgba: var(--app-button-primary-background-color--rgba);

$button-primary-color: var(--app-button-primary-color);
$button-primary-color--rgb: var(--app-button-primary-color--rgb);
$button-primary-color--rgba: var(--app-button-primary-color--rgba);

$button-secondary-background-color: var(--app-button-secondary-background-color);
$button-secondary-background-color--rgb: var(--app-button-secondary-background-color--rgb);
$button-secondary-background-color--rgba: var(--app-button-secondary-background-color--rgba);

$button-secondary-color: var(--app-button-secondary-color);
$button-secondary-color--rgb: var(--app-button-secondary-color--rgb);
$button-secondary-color--rgba: var(--app-button-secondary-color--rgba);

$video-player-button-color: var(--app-video-player-button-color);
$video-player-button-color--rgb: var(--app-video-player-button-color--rgb);
$video-player-button-color--rgba: var(--app-video-player-button-color--rgba);

$video-player-button-background-color: var(--app-video-player-button-background-color);
$video-player-button-background-color--rgb: var(--app-video-player-button-background-color--rgb);
$video-player-button-background-color--rgba: var(--app-video-player-button-background-color--rgba);

$video-player-button-hover-color: var(--app-video-player-button-hover-color);
$video-player-button-hover-color--rgb: var(--app-video-player-button-hover-color--rgb);
$video-player-button-hover-color--rgba: var(--app-video-player-button-hover-color--rgba);

$video-player-button-hover-background-color: var(--app-video-player-button-hover-background-color);
$video-player-button-hover-background-color--rgb: var(--app-video-player-button-hover-background-color--rgb);
$video-player-button-hover-background-color--rgba: var(--app-video-player-button-hover-background-color--rgba);

$video-player-progress-value-color: var(--app-video-player-progress-value-color);
$video-player-progress-value-color--rgb: var(--app-video-player-progress-value-color--rgb);
$video-player-progress-value-color--rgba: var(--app-video-player-progress-value-color--rgba);

$video-player-progressbar-color: var(--app-video-player-progressbar-color);
$video-player-progressbar-color--rgb: var(--app-video-player-progressbar-color--rgb);
$video-player-progressbar-color--rgba: var(--app-video-player-progressbar-color--rgba);

$scrollbar-track-color: var(--app-scrollbar-track-color);
$scrollbar-track-color--rgb: var(--app-scrollbar-track-color--rgb);
$scrollbar-track-color--rgba: var(--app-scrollbar-track-color--rgba);

$scrollbar-thumb-color: var(--app-scrollbar-thumb-color);
$scrollbar-thumb-color--rgb: var(--app-scrollbar-thumb-color--rgb);
$scrollbar-thumb-color--rgba: var(--app-scrollbar-thumb-color--rgba);

